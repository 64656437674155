<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('withdraw_option')}}</van-step>
			<van-step>{{translate('withdrawal_amount')}}</van-step>
			<van-step>{{translate('accounts_receivable')}}</van-step>
			<van-step>{{translate('moderated')}}</van-step>
		</van-steps>
		<div
			v-if="wanCard"
			style="padding: 15px 10px;
				background: #fff;
				margin: 10px;
				border-radius: 5px;
				display:flex;align-items: center"
			@click="wanShow=true">
			<p style="color: #666;padding-left: 15px;font-weight: bold">万事达开卡申请</p>
		</div>
		<van-dialog
			v-model="wanShow"
			title="提示"
			show-cancel-button
			@confirm="onWan"
			@cancel="wanShow=false"
		>
			<div style="padding: 20px 0;text-align: center;" >开卡费用需要收取{{prices}}港币，请确认！</div>
			
		</van-dialog>
		<div
			v-if="speedpayCard"
			style="padding: 15px 10px;
				background: #fff;
				margin: 10px;
				border-radius: 5px;
				display:flex;align-items: center"
			@click="speedpayShow=true">
			<p style="color: #666;padding-left: 15px;font-weight: bold">SpeedPay开卡申请</p>
		</div>
		<van-dialog
			v-model="speedpayShow"
			title="提示"
			show-cancel-button
			@confirm="onSpeedpay"
			@cancel="speedpayShow=false"
		>
			<div style="padding: 20px 0;text-align: center;" >开卡费用需要收取{{prices}}港币，请确认！</div>
			
		</van-dialog>
		<h4 class="h4_title">{{translate('account_balance')}}</h4>
		
		<van-cell-group>
			<van-cell title="账户余额" :value="balance_show" />
		</van-cell-group>

		<van-cell-group>
			<van-cell :title="translate('amount_that_can_be_withdrawn')" :value="available_show" />
		</van-cell-group>

		<h4 class="h4_title">{{translate('amount_withdrawal_amount')}}</h4>

		<van-field
			clearable
			type="number"
			v-model="formItem.total"
			name="total"
			:label="translate('amount_withdrawal_amount')"
			:placeholder="translate('amount_withdrawal_amount')"
			:rules="[{ required: true, trigger: 'onChange', message: translate('fill_in_the_withdrawal_amount') }]"
		/>

		<h4 class="h4_title">支付密码</h4>

		<van-field
			clearable
			type="password"
			v-model="formItem.password"
			name="password"
			label="支付密码"
			placeholder="支付密码"
		/>

		<div class="common_block">
			<van-button type="warning" block @click="next">{{translate('amount_next_step')}}</van-button>
		</div>

		<div class="common_block">
			<p style="text-align: right;color: blue;" @click="goHistory">{{translate('withdrawal_record')}}</p>
		</div>
	</div>
</template>

<script>
import {SHA1} from '../../utils/sha1.js';
	export default {
		name: 'chujin_amount',
		data() {
			return {
				speedpayShow: false,
				wanShow: false,
				speedpayCard:false,
				wanCard:false,
				step_active: 1,

				formItem: {
					c: 0,
					total: "",
					token: "",
					bank_type: "",
					bank_info: "",
					password: ""
				},
				
				symbol: "",
				balance_show: "",
				balance: 0,
				available_show: "",
				prices:''
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				if (window.sessionStorage.getItem('c') == undefined) {
					this.$router.replace('/withdraw/index?token=' + this.$route.query.token)
				}

				this.formItem.token = this.$route.query.token
				this.formItem.c = window.sessionStorage.getItem('c')
				if (window.sessionStorage.getItem('total')) {
					this.formItem.total = window.sessionStorage.getItem('total')
				}

				this.init()
				if(this.formItem.c ==1 ){
					this.getPrice(1);
					this.getWanCard();
				}else if(this.formItem.c ==2){
					this.getPrice(2);
					this.getSpeedpayCard();
				}
				
				
			}
		},
		methods: {
			getPrice(type){
				this.$axios({
					method: 'post',
					url: 'speedpay_common/getPrice', 
					data:{
						card_type:type
					}
				}).then ((res) => {
					if(res.error_code==0){
						this.prices=res.data.price;
					}
				})
			},
			onWan() {
				// 确认操作
				this.wanShow = false;
				this.$router.push('/speedpay/index?token=' + this.formItem.token + '&card_type=1')
			},
			getWanCard(){
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/getSpeedpayCard', 
					data:{
						token:this.formItem.token,
						bank_type:5
					}
				}).then ((res) => {
					if(res.error_code==0){
						this.wanCard=false;
					}else if(res.error_code==1000){
						// this.wanCard=true;
						this.getEnable1();
					}
				})
			},
			getEnable1(){
				this.$axios({
					method: 'post',
					url: 'speedpay_common/getEnable'
				}).then ((res) => {
					if(res.error_code==0){
						if(res.data.master==1){
							this.wanCard=true;
						}
					}
				})
			},
			onSpeedpay() {
				// 确认操作
				this.speedpayShow = false;
				this.$router.push('/speedpay/index?token=' + this.formItem.token + '&card_type=2')
			},
			getSpeedpayCard(){
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/getSpeedpayCard', 
					data:{
						token:this.formItem.token,
						bank_type:4
					}
				}).then ((res) => {
					if(res.error_code==0){
						this.speedpayCard=false;
					}else if(res.error_code==1000){
						// this.speedpayCard=true;
						this.getEnable2();
					}
				})
			},
			getEnable2(){
				this.$axios({
					method: 'post',
					url: 'speedpay_common/getEnable'
				}).then ((res) => {
					if(res.error_code==0){
						if(res.data.union==1){
							this.speedpayCard=true;
						}
					}
				})
			},
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/index', 
					data: {
						token: this.formItem.token,
						c: this.formItem.c
					}
				}).then ((res) => {
					if (res.success) {
						this.withdraw_tip = res.data.withdraw_tip
						
						this.symbol = res.data.symbol
						this.balance = parseFloat(res.data.available)
						this.balance_show = res.data.balance_show
						this.available_show = res.data.available_show

						window.sessionStorage.setItem('balance', this.balance)
					}
				})
			},
			next() {

				if (this.formItem.total == '') {
					this.$dialog.alert({
						message: this.translate('fill_in_the_withdrawal_amount')
					})
					
					return false
				}
				if (this.formItem.password == '') {
					this.$dialog.alert({
						message: '请输入支付密码'
					})
					
					return false
				}
				var total = parseFloat(this.formItem.total)
		
				if (total <= 0) {
					this.$dialog.alert({
						message: this.translate('fill_in_the_withdrawal_amount')
					})
					
					return false
				}
				
				if (this.formItem.c == 3 && total < 50) {
					this.$dialog.alert({
						message: this.translate('minimum_usdt_withdrawal_is')
					})
					
					return false
				}

				if (total > this.balance) {
					this.$dialog.alert({
						message: this.translate('insufficient_account_balance') + this.available_show
					})
					
					return false
				}

				window.sessionStorage.setItem('total', total)
				window.sessionStorage.setItem('symbol', this.symbol)
				let mps= SHA1(this.formItem.password)
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/methods', 
					data: {
						token: this.formItem.token,
						currency_id: this.formItem.c,
						total: this.formItem.total,
						pay_password: mps
					},
				}).then ((res) => {
					if (res.success) {
						this.$router.push('/withdraw/type?token=' + this.formItem.token+'&mps='+mps)
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
				
			},
			goHistory() {
				this.$router.push('/withdraw/history?token=' + this.formItem.token)
			}
		},
	}
</script>
